export const allocationStatusEnums = {
  PLACEMENT_REVIEW: 0,
  FORMALIZATION: 1,
  DENIED: 2,
  PLACEMENT_REQUEST: 3,
  PENDING_COMMITMENT_TERM_SIGNED: 4,
  PENDING_INVESTMENT_PROXY_SIGNED: 5,
  TRANSFER_FUNDS: 6,
  OPERATION_SETTING: 7,
  SENT_CAPTABLE: 8,
  CANCELLED: 9,
};

export const allocationTabEnums = {
  PROGRESS: 1,
  FINALIZED: 2,
};

export const allocationTableEnum = {
  INVESTOR: 1,
  DATE: 2,
  VALUE: 3,
  COMPANY: 4,
  ROUND: 5,
  VEHICLE: 6,
  STATUS: 7,
};
